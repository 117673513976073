import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/stories.jpg"
import Layout from "../../components/layoutMainCustom"
import { Box, Image, Badge, Grid } from "@chakra-ui/core"
import ScottStory from "../../images/scott_story.jpg"
import CoryStory from "../../images/cory_story.jpg"
import MaggieStory from "../../images/maggie_story.jpg"
import Christmas from "../../images/christmasSeries.jpg"
import AllStories from "../../components/allStories"
import SEO from "../../components/seo"

function StoriesBox({
  imageUrl,
  imageAlt,
  storyTitle,
  storyLink,
  date,
  badge,
  storyCategory,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={storyLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {storyCategory}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {storyTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Stories @ Cross Church"
    SecondText="How God's story intersects with ours."
  >
    <SEO title="Our Stories" />
    <AllStories />
  </Layout>
)

export default IndexPage
